<template>
  <v-container class="home">
    <v-row justify="center">
      <v-col cols="auto" class="no-hope">
        <p>Kein Grund zur Hoffnung?</p>
      </v-col>
    </v-row>

    <v-row justify="center">
      <v-col cols="auto" class="goodies">
        <h2 class="dark-gray">Hier gibt's</h2>
        <h2 class="secondary-font green">hoffnungsvolle</h2>
        <h2 class="secondary-font green">Goodies</h2>
      </v-col>
    </v-row>

    <v-row justify="center">
      <v-col cols="auto" class="book-names">
        <p>Neues Testament</p>
        <p>Ostern – unglaublich?</p>
        <p>Tatsächlich: ERlebt!</p>
        <p><img src="@/assets/arrow-down.svg" class="scroll-arrow"></p>
      </v-col>
    </v-row>

    <v-row justify="center">
      <v-col cols="auto">
        <h2 id="nt-title" class="secondary-font book-title">Neues Testament</h2>
      </v-col>
    </v-row>

    <v-row justify="center">
      <v-col cols="auto">
        <h2 id="ou-title" class="secondary-font book-title">Ostern – unglaublich?</h2>
      </v-col>
    </v-row>

    <v-row justify="center">
      <v-col cols="auto">
        <h2 id="tel-title" class="secondary-font book-title">Tatsächlich: ERlebt!</h2>
      </v-col>
    </v-row>

    <div id="nt-container" class="book-container">
      <img src="@/assets/nt.jpg">
      <p>
        <b>Deine Fragen. Gottes Antworten.</b><br>
        Gottes zeitlose Botschaft an uns Menschen.
      </p>
      <button class="button" @click="orderNt = true">Jetzt gratis bestellen</button>
      <v-dialog v-model="orderNt">
        <Order selection="Neues Testament (Bibel)" :active="orderNt" @close="orderNt = false"/>
      </v-dialog>
    </div>

    <div id="ou-container" class="book-container">
      <img src="@/assets/ou.jpg">
      <p>
        <b>Argumente für Interessierte.</b> Von einer Historikerin.
      </p>
      <button class="button" @click="orderOu = true">Jetzt gratis bestellen</button>
      <v-dialog v-model="orderOu">
        <Order selection="Ostern – unglaublich?" :active="orderOu" @close="orderOu = false"/>
      </v-dialog>
    </div>

    <div id="tel-container" class="book-container">
      <img src="@/assets/tel.jpg">
      <p>
        <b>Argumente für Skeptiker.</b> Von einem Skeptiker.
      </p>
      <button class="button" @click="orderTel = true">Jetzt gratis bestellen</button>
      <v-dialog v-model="orderTel">
        <Order selection="Tatsächlich: ERlebt" :active="orderTel" @close="orderTel = false"/>
      </v-dialog>
    </div>
  </v-container>

</template>

<script>

import Vue from "vue";
import Component from "vue-class-component";
import {CSSRulePlugin} from "gsap/CSSRulePlugin";
import Order from "@/components/Order.vue";
import gsap from "gsap";

@Component({
  components: {Order}
})
export default class Home extends Vue {

  data() {
    return {
      solutionExpanded: false,
      orderNt: false,
      orderTel: false,
      orderOu: false,
    };
  }

  mounted() {
    this.animate();
  }

  animate() {
    this.animateBook("#nt-title", "#nt-container");
    this.animateBook("#tel-title", "#tel-container");
    this.animateBook("#ou-title", "#ou-container");

  }

  animateBook(trigger, container) {
    const label = "book-" + container;
    gsap.timeline({
      scrollTrigger: {
        trigger: trigger,
        pin: false,
        start: 'center 65%',
        end: "center 55%",
        scrub: true
      },
      defaults: {duration: 1, ease: 'none'}
    })
        .addLabel(label)
        .to(container, {
          visibility: "visible",
          duration: 0.01
        }, label)
        .from(container + " img", {
          translateY: "10vh"
        }, label)
        .to(container, {
          opacity: 1,
        }, label);

    gsap.timeline({
      scrollTrigger: {
        trigger: trigger,
        pin: false,
        start: 'center 35%',
        end: "center 25%",
        scrub: true
      },
      defaults: {duration: 1, ease: 'none'}
    })
        .to(container, {
          opacity: 0,
        })
        .to(container, {
          visibility: "hidden",
          duration: 0.01
        })
  }

}
</script>

<style lang="scss">
@import "../style/global.scss";

.home {
  font-size: 20px;
  font-weight: 300;
  line-height: 1.5;
  position: relative;

  @media screen and (max-width: 800px) {
    font-size: 16px;
  }

  @media screen and (max-width: 600px) {
    font-size: 14px;
  }

  @media screen and (max-width: 370px) {
    font-size: 12px;
  }

  h2 {
    display: block;
    font-size: 3.6em;
    text-align: center;

    @media screen and (max-width: 400px) {
      font-size: 3.2em;
    }
  }

  h3 {
    color: black;
    font-weight: 500;
    font-size: 2.5em;
    margin: 0 0 0.6em;
  }

  h4 {
    font-weight: 500;
    font-size: 2em;
    margin: 0 0 0.6em;
  }

  p {
    margin: 0;
  }
}

p {
  text-align: center;
}

.goodies {

  margin-top: 20vh;
  margin-bottom: 15vh;

  * {
    text-align: center;
  }
}

.book-names {
  p {
    color: $dark-gray;

    &:not(:last-of-type) {
      margin-bottom: 0.8em;
    }
  }

  .scroll-arrow {
    margin-top: 1.6em;
  }
}

.book-title {
  margin: 40vh 0;

  color: $light-gray;
}

.book-container {
  width: 100%;
  opacity: 0;
  visibility: hidden;
  position: fixed;
  top: 50vh;
  left: 50%;
  transform: translate(-50%, -50%);

  display: flex;
  flex-direction: column;
  flex: 0 1 auto;
  align-items: center;
  font-size: 0.8em;
  will-change: opacity;

  @media screen and (max-width: 600px) {
    font-size: 1em;
  }

  p {
    margin-top: 1em;
    margin-bottom: 1em;
    color: $dark-gray;
  }

  img {
    max-width: 62.5vw;
    height: 60vh;
    object-fit: contain;
    will-change: transform;
  }

  button {
    display: inline;
    color: $green !important;
  }
}

</style>
