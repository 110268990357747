import Vue from 'vue'
import App from './App.vue'
import router from './router'
import Vuetify from "vuetify";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { CSSRulePlugin } from "gsap/CSSRulePlugin";
import { TextPlugin } from "gsap/TextPlugin";

import 'vuetify/dist/vuetify.min.css'
import '@mdi/font/css/materialdesignicons.css'


gsap.registerPlugin(ScrollTrigger, CSSRulePlugin, TextPlugin);

Vue.config.productionTip = false

const vuetify = new Vuetify(
    {
      options: {
        customProperties: true
      }
    }
);

Vue.use(Vuetify);

new Vue({
  router,
  vuetify,
  render: h => h(App)
}).$mount('#app')
